import React from "react";
import Slider from "react-slick";
import { cl1, cl2, cl3, cl4, cl5 } from '../assets/images';


export default function SimpleSlider() {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      <div>
            <div class="cl-logo-img">
                <img src={cl1}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl2}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl3}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl4}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl5}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl1}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl2}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl3}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl4}/>
            </div>
        </div>
        <div>
            <div class="cl-logo-img">
                <img src={cl5}/>
            </div>
        </div>
    </Slider>
  );
}