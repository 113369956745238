import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { loader } from '../../assets/images';
import { popupInnerImg } from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "amazonkindlepublishing.co"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=244475';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonkindlepublishing.co/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div class="row">
          <div class="col-lg-5 col-md-5 pr-0">
            <div class="popup-form-img">
              <img src={popupInnerImg} alt="" class="hide-mob" />
            </div>
          </div>
          <div class="col-lg-7 col-md-7 mobile-p pl-0">
            <div class="popup-form-sec">
              <div class="cross-mark" onClick={onCloseModal}>
                <a href="javascript:;">
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <div class="popup-form-sec-title text-center">
                <h2>Get Special Discount</h2>
                <p>Don't miss out on special discount offer</p>
              </div>
              <form
                class="form_submission popup-form"
                method="POST"
                onSubmit={onSubmitFunc}
              >
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Your Name"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <input
                  type="number"
                  name="phone"
                  placeholder="Enter Your Number"
                  minlength="10"
                  maxlength="12"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
                {errors && (
                  <div className="error">
                    Phone number must be at least 9 digits long
                  </div>
                )}
                <textarea
                  class="form-control"
                  name="message"
                  placeholder="Talk About Your Project"
                  id=""
                  cols="30"
                  rows="5"
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                ></textarea>

                {loading == true ? (
                  <div class="loader">
                    <img alt="loader" src={loader} />
                  </div>
                ) : (
                  <input
                    class="popup-form-btn"
                    name="submit"
                    type="Submit"
                    value="Submit"
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
