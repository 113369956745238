import './App.css';
import React, { useState, useEffect } from 'react';
import SimpleSlider from './components/SimpleSlider';
import {
  bannerImg,
  creativeLogo,
  logo,
  loader,
  visa,
  visa1,
  visa3,
  visa4,
  visa5,
  ssl,
  money,
  homeFourth,
  serviceImg1,
  globalBookMarketingServiceImage,
  chooseIcon,
  logoWhite,
} from './assets/images';
import FlushExample from './components/FlushExample';
import { PHONE, PHONE_HREF, WEBSITE_LOGO, WEBSITE_NAME } from './config';
import axios from 'axios';
import { ModalSection } from './layout/modalSection';

function App() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "amazonkindlepublishing.co"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=244475';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonkindlepublishing.co/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Define the function to toggle the chat
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.show();
      }
    };

    // Attach event listeners when the component mounts
    const chatButton = document.querySelectorAll('.chat'); // Replace '.chat' with the appropriate button selector

    chatButton.forEach(function (clickButton) {
      // clickButton.addEventListener('click', clickButton)
      if (clickButton) {
        console.log(clickButton);
        clickButton.addEventListener('click', toggleChat);
      }

      // Cleanup: Remove event listeners when the component unmounts
      return () => {
        if (clickButton) {
          console.log(clickButton);
          clickButton.removeEventListener('click', toggleChat);
        }
      };
    });
  }, []);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 5);
    });
  }, []);

  return (
    <div className="App">
      <header class={scroll ? 'header-main showmenu' : 'header-main'}>
        <div class="container">
          <div class="row rows_Section">
            <div class="col-md-3">
              <div class="logowrp">
                <a href="">
                  <img class="logo-white" src={logoWhite} />
                  <img class="logo-dark" src={logo} />
                </a>
              </div>
            </div>
            <div class="col-md-9 col-md-5 buttons_links my-auto">
              <div class="btnmain">
                <ul>
                  <li>
                    <a class="nmbtwrp" href={PHONE_HREF}>
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      {PHONE}
                    </a>
                  </li>
                  <li class="Live_chat">
                    <a class="blink chat" href="javascript:;">
                      Live Chat
                    </a>
                  </li>
                  <li class="get_started_btn">
                    <a class="btnwrp" href="javascript:;" onClick={onOpenModal}>
                      Get Started
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section class="home-banner">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="txtwrp">
                <h1 class="wow fadeInUp">
                  Boost Your Sales With Right Book Promotion
                </h1>
                <h4 class="wow fadeInUp">
                  Providing Compelling Promotional Opportunities to Rising
                  Authors
                </h4>
                <p class=" wow fadeInUp">
                  Promote your book the right way & take your book from the back
                  of the shelf to Bestseller. Maximize your Books Sales from our
                  Certified Marketing Professionals.
                </p>
                <div class="btnwrp">
                  <a class="btn-1" href="javascript:;" onClick={onOpenModal}>
                    Let's Get Started
                  </a>
                  <a class="btn-2 chat" href="javascript:;">
                    Chat Now
                  </a>
                </div>
              </div>
            </div>
            <img class="banneimg" src={bannerImg} />
            <div class="col-md-4 form-bnr-main">
              <div class="formbaner  wow fadeInUp">
                <div class="txtdiv">
                  <p class=" wow fadeInUp">
                    <span> Sign Up </span> For Expert Consultation &
                    <strong> Exclusive Discount</strong>
                  </p>
                </div>
                <form
                  class="form_submission"
                  method="POST"
                  onSubmit={onSubmitFunc}
                >
                  <div class="lablewrp">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Your Name"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                  <div class="lablewrp">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                  <div class="lablewrp">
                    <i
                      class="fa fa-volume-control-phone"
                      aria-hidden="true"
                    ></i>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Enter Your Number"
                      minlength="10"
                      maxlength="12"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                    {errors && (
                      <div className="error">
                        Phone number must be at least 9 digits long
                      </div>
                    )}
                  </div>
                  <div class="lablewrp">
                    <i class="fas fa-envelope-open-text"></i>
                    <textarea
                      class="form-control"
                      name="message"
                      placeholder="Talk About Your Project"
                      cols="30"
                      rows="5"
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div class="lablewrp">
                    {loading == true ? (
                      <div class="loader">
                        <img alt="loader" src={loader} />
                      </div>
                    ) : (
                      <input
                        class="btnwrp"
                        name="submit"
                        type="Submit"
                        value="Submit"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="client-logo">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="cl-logo-slid">
                <div class="cl-logo-slider">
                  <SimpleSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="secondwrp">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="txtwrp">
                <h2 class="main-heading  wow fadeInUp">
                  <span>A</span>BOUT
                </h2>
                <h4 class="prime-heading  wow fadeInUp">{WEBSITE_NAME}</h4>
                <p class="main-pera  wow fadeInUp">
                  Marketing isn’t just a specialization; it’s an art and
                  {WEBSITE_NAME} consists of a team of talented artists who pour
                  their talent and dedication into the project they’ve been
                  assigned. From book publicity, to influencer campaigns,
                  {WEBSITE_NAME} has it all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="broadwrp secndsec">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-7 my-auto">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>E</span>XQUISITE
                </h2>
                <h4 class="prime-heading wow fadeInUp">
                  BOOK PROMOTION SERVICES
                </h4>
                <p class="main-pera wow fadeInUp">
                  Your credibility is our responsibility. Our exquisite book
                  promotion services include social media marketing, media
                  exposure and original reviews from authors and readers. All
                  your story needs is a platform, and {WEBSITE_NAME} is just
                  that!
                </p>
              </div>
              <div class="btnwrp">
                <a class="btn-1" href="javascript:;" onClick={onOpenModal}>
                  Let's Get Started
                </a>
                <a class="btn-2 chat" href="javascript:;">
                  Chat Now
                </a>
              </div>
            </div>
            <div class="col-md-5 col-lg-5 board-sec-img">
              <div class="imgwrp wow fadeInRight">
                <img class="lazy" src={homeFourth} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="achievementwrp">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-7">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>B</span>OOK PUBLICITY
                </h2>
                <h4 class="prime-heading wow fadeInUp"> SERVICES</h4>
                <p class="main-pera wow fadeInUp">
                  You might be holding the next best seller, but how would the
                  world know? {WEBSITE_NAME} offers Book Publicity Services
                  including carefully thought through PR campaigns and
                  pre-calculated marketing campaigns to push your book up the
                  shelves of success!
                </p>
              </div>
              <div class="txtdiv">
                <ul>
                  <li>
                    <p>Years Of Experience</p>
                    <h2>9+</h2>
                    <span>
                      We have over 9 years of experience in Designing, Writing
                      and Marketing
                    </span>
                  </li>
                  <li>
                    <p>Books Published</p>
                    <h2>2500+</h2>
                    <span>
                      The counter is going up day by day. We know how to publish
                      books in a fast manner.
                    </span>
                  </li>
                  <li>
                    <p>Clients</p>
                    <h2>500+</h2>
                    <span>
                      {WEBSITE_NAME} has clients from all around the globe.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="serviceswpr">
        <div class="container">
          <div class="row">
            <div class="col-md-5 col-lg-5 services-img-box">
              <div class="imgwrp">
                <img class="lazy" src={serviceImg1} />
              </div>
            </div>
            <div class="col-md-12 col-lg-7">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>W</span>HAT YOU GET
                </h2>
                {/* <h6 class="wow fadeInUp">Our services are both inexpensive and efficient. We have the contacts, relationships, and a demonstrated track record of success. We are book lovers, and we know what it takes to make your book stand out. We concentrate our efforts on the following areas based on the book and the author's objectives:</h6> */}
                <p class="main-pera wow fadeInUp">
                  This isn’t business, it is merely the utilization of art to
                  give a masterpiece the platform it merits. Our
                  professionally-skilled marketers ensure your book stands out,
                  and is recognized for the uniqueness it possesses. We aren’t
                  just marketers, but bookworms as well. Hence, we value each
                  project we handle, and work on it one of our own!
                </p>
                <ul>
                  <li>Podcasts</li>
                  <li>Increase book sales</li>
                  <li>Extensive social media coverage</li>
                  <li>Radio and television interviews</li>
                  <li>Long-term digital marketing plan</li>
                  <li>Round-the-clock professional assistance</li>
                  <li>Expand your international reach.</li>

                  <li>Make your book a New York Times best-seller.</li>
                  <li>Create and improve author branding</li>
                  <li>Generate enthusiasm for speaking engagements</li>
                  <li>
                    Allow writers to leverage their books and media coverage as
                    marketing tools to attract new clients.
                  </li>
                  <li>
                    New clients will open up newer avenues like consultancy and
                    other writing opportunities.
                  </li>
                </ul>
              </div>
              <div class="btnwrp">
                <a class="btn-1" href="javascript:;" onClick={onOpenModal}>
                  Let's Get Started
                </a>
                <a class="btn-2 chat" href="javascript:;">
                  Chat Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ctawrp">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>B</span>OOK REVIEWS
                </h2>
                <h4 class="prime-heading wow fadeInUp">
                  “If you love the work you do, others will too!” <br />
                  {WEBSITE_NAME}
                </h4>
                <p class="main-pera wow fadeInUp">
                  One of the most effective marketing tactics is to have
                  original book reviews, written thoughtfully, and ensuring it
                  provides every reader the insight they need. {WEBSITE_NAME}
                  collaborates with famous and valuable book bloggers,
                  reviewers, writers and influencers to generate the required
                  target audience and market your book!
                </p>
              </div>
              <div class="btnwrp">
                <a class="btn-1" href="javascript:;" onClick={onOpenModal}>
                  Let's Get Started
                </a>
                <a class="btn-2 chat" href="javascript:;">
                  Chat Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="broadwrp why-should">
        <div class="container">
          <div class="row">
            <div class="col-md-5 col-lg-5 broad-img-box">
              <div class="imgwrp wow fadeInLeft">
                <img class="lazy" src={globalBookMarketingServiceImage} />
              </div>
            </div>
            <div class="col-md-12 col-lg-7 my-auto">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>W</span>HY SHOULD
                </h2>
                <h4 class="prime-heading  wow fadeInUp"> YOU TRUST US?</h4>
                <p class="main-pera  wow fadeInUp">
                  Our team of professional marketers work with efficiency and
                  dedication to provide your book the limelight it truly
                  deserves. Each individual is experienced, giving them the
                  expertise to deal with every genre and challenge. At
                  {WEBSITE_NAME}, we do not try, we achieve and we accomplish.
                </p>
              </div>
              <div class="btnwrp ">
                <a class="btn-1" href="javascript:;" onClick={onOpenModal}>
                  Let's Get Started
                </a>
                <a class="btn-2 chat" href="javascript:;">
                  Chat Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="processwrp">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>W</span>hat we do for your
                </h2>
                <h4 class="prime-heading wow fadeInUp">
                  BOOK TO GET RECOGNIZED!
                </h4>
                <p class="main-pera wow fadeInUp">
                  At {WEBSITE_NAME}, we recognize potential, and ensure the
                  world recognizes it too! We provide a list of services to
                  transform this dream to reality:
                </p>
              </div>
              <div class="mainwrp">
                <ul class="wow fadeInUp">
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>
                      Search engine optimization <br />
                      (SEO)
                    </span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>
                      Maximizing Goodreads
                      <br /> presence
                    </span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>Amazon optimization</span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>Website review and analysis</span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>Effective social media tactic</span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>Book Award Entries</span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>Interviews and Media outreach</span>
                  </li>
                  <li>
                    <img class="lazy" src={chooseIcon} />
                    <span>Magazine and Newspapers</span>
                  </li>
                  <li class="responsive-d-none">
                    <img class="lazy" src={chooseIcon} />
                    <span>Broadcast Campaign</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bottomfrom">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="txtwrp">
                <h2 class="main-heading wow fadeInUp">
                  <span>W</span>ITH {WEBSITE_NAME}
                </h2>
                <h4 class="prime-heading wow fadeInUp">
                  YOUR BOOK GETS THE RECOGNITION IT DESERVES!
                </h4>
                <p class="main-pera wow fadeInUp">
                  We help authors in getting their work acknowledged. Create a
                  fan base, receive real reviews from actual readers, obtain
                  excellent media coverage, boost sales, and get the word out
                  about your work!
                </p>
                <p class="main-pera wow fadeInUp mb-5">
                  Call us and talk to one of our book marketing experts today!
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <h3 class="mb-4">Frequently Asked Questions</h3>
              <FlushExample />
            </div>
            <div class="col-md-6">
              <div class="fromwrp">
                <form
                  class="form_submission"
                  method="POST"
                  onSubmit={onSubmitFunc}
                >
                  <div class="lablewrp">
                    <div class="lab-icon"></div>
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Your Name"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                  <div class="lablewrp">
                    <div class="lab-icon"></div>
                    <i class="fa fa-envelope-open" aria-hidden="true"></i>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email Address"
                      name="email"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                  <div class="lablewrp">
                    <div class="lab-icon"></div>
                    <i
                      class="fa fa-volume-control-phone"
                      aria-hidden="true"
                    ></i>
                    <input
                      name="phone"
                      type="number"
                      placeholder="Phone Number"
                      minlength="10"
                      maxlength="12"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                    {errors && (
                      <div className="error">
                        Phone number must be at least 9 digits long
                      </div>
                    )}
                  </div>
                  <div class="lablewrp">
                    <div class="lab-icon">
                      <i class="fa fa-commenting" aria-hidden="true"></i>
                      <textarea
                        class="form-control"
                        name="message"
                        placeholder="Talk about services you're looking for."
                        required
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div class="lablewrp">
                    {loading == true ? (
                      <div class="loader">
                        <img alt="loader" src={loader} />
                      </div>
                    ) : (
                      <input
                        class="btnmain"
                        name="submit"
                        type="Submit"
                        value="Submit"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="tooter-bottom">
        <div class="container">
          <div class="row top-footer">
            <div class="col-md-4">
              <div class="flex">
                <div class="imgwrp footerbotom">
                  <img src={money} />
                </div>
                <div class="txtwrp footerbotom">
                  <h4>100% SATISFACTION</h4>
                  <p>
                    Our services are 100% confidential.
                    <br /> We Strive for 100% satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="flex">
                <div class="imgwrp footerbotom">
                  <img src={ssl} />
                </div>
                <div class="txtwrp footerbotom">
                  <h4>SSL SECURE PAYMENT</h4>
                  <p>
                    Your information is protected by 256-bit SSL encryption.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h4 class="payment">ACCEPTED PAYMENT METHODS</h4>
              <div class="imgwrp">
                <ul class="paymentwrp">
                  <li>
                    <img src={visa} />
                  </li>
                  <li>
                    <img src={visa1} />
                  </li>
                  <li>
                    <img src={visa3} />
                  </li>
                  <li>
                    <img src={visa4} />
                  </li>
                  <li class="mr-0">
                    <img src={visa5} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-8">
              <div class="copyright">
                <p>Copyright © 2024 {WEBSITE_NAME} | All rights reserved.</p>
              </div>
            </div>
            <div class="col-md-4  ml-auto">
              <div class="main-pages">
                <ul>
                  <li>
                    <a href="javascript:;">Privacy Policy </a>
                  </li>
                  &nbsp;|&nbsp;
                  <li>
                    <a href="javascript:;">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="floating-form">
        <div class="inner-float">
          <div class="strip" onClick={onOpenModal}>
            <p class="enq">Enquiry</p>
            <span class="icon-paper-plane plane"></span>
            <a href="javascript:;" class="btn-close">
              <div class="hamb">
                <div class="bar one"></div>
                <div class="bar second"></div>
                <div class="bar two"></div>
              </div>
            </a>
          </div>
          <div class="f-whole">
            <div class="left-sec">
              <div class="logowrp">
                <img src={logo} />
              </div>
              <h3>What makes {WEBSITE_NAME} stand apart?</h3>
              <p>
                So whether it is an autobiography, a self-help book, a business
                publication, or a work of fiction, we have got you covered!
              </p>
              <form
                class="form_submission"
                method="POST"
                onSubmit={onSubmitFunc}
              >
                <div class="">
                  <div class="">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Your Name"
                      required
                    />
                  </div>

                  <div class="">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email Address"
                      name="email"
                      required
                    />
                  </div>

                  <div class="">
                    <input
                      name="phone"
                      type="number"
                      placeholder="Phone Number"
                      minlength="10"
                      maxlength="12"
                      required
                    />
                    {errors && (
                      <div className="error">
                        Phone number must be at least 9 digits long
                      </div>
                    )}
                  </div>

                  <div class="">
                    <textarea
                      class="form-control"
                      name="message"
                      placeholder="Talk about services you're looking for."
                      required
                    ></textarea>
                  </div>

                  <div class="">
                    <div class="form-bottom formb-floating">
                      <div class="formb-right">
                        {loading == true ? (
                          <div class="loader">
                            <img alt="loader" src={loader} />
                          </div>
                        ) : (
                          <input
                            class="btnwrp"
                            name="submit"
                            type="Submit"
                            value="Submit"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="f-rght rght-sec"></div>
          </div>
        </div>
      </div>
      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
}

export default App;
