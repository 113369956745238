import Accordion from 'react-bootstrap/Accordion';

export default function FlushExample() {
  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Do I need to hire a book marketing professional?</Accordion.Header>
        <Accordion.Body>
        Hiring an agency will help you achieve greater success. Book marketing agencies have the experience, resources, and knowledge needed to market authors and their books.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What’s the best way to market my book online?</Accordion.Header>
        <Accordion.Body>
        There are many helpful online book marketing strategies. In addition to building your author website, you should create social profiles, an author profile on Amazon, and a blog. These off-site profiles should be connected to your website, so visitors can easily access them.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Do I need an author website?</Accordion.Header>
        <Accordion.Body>
        Every author should have an author website. Your website is the first place readers, media, booksellers, and book reviewers will turn to gather more information about you and your book. It should have a clean, easy-to-navigate layout and include relevant information about you and the book.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Should I sell my book on Amazon?</Accordion.Header>
        <Accordion.Body>
        Yes, definitely. Amazon is one of the top online platforms for buying books and provides numerous promotional resources for authors, such as the Amazon Author Central program.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Does my book require an ISBN code?</Accordion.Header>
        <Accordion.Body>
        Yes. Most bookstores require an ISBN, and library catalogs and search engines prefer them. Learn more about the importance of ISBN codes here
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}